body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  color: white;
}
p{
  margin: 0;
  margin-bottom: 0 !important;
}
.flex-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-data {
  font-weight: 400;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.1);
}
.ant-form {
  font-family: "Lato";
}
/* used to set the border color of antd-select dropdown */
.ant-select-dropdown {
  border: 1px solid rgba(196, 196, 196, 0.45);
}

/* custom the transparent modal UI */
.modal-dialog .ant-modal-content {
  height: 100%;
  width: 100%;
}
.modal-dialog .ant-modal-body {
  height: 100%;
  width: 100%;
  padding: 32px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-dialog .ant-modal-body .video-container {
  border: 5px solid white;
}
.modal-dialog .ant-modal {
  width: 100% !important;
}
.ant-picker-cell-disabled{
  filter:blur(1px) !important;
  }

.scroll-without-header {
  height: calc(100% - 74px);
  overflow: auto;
}

.scroll-without-header::-webkit-scrollbar {
  width: 0px;
}
.cursor {
  cursor: pointer;
}
.addtime-inner{
  background: rgba(19, 19, 19, 1) !important;
}
.ant-message-notice-content {
  background: #5a595991 !important;
  color: #fff;
  letter-spacing: 0.3px;
  border: 3px solid #787274;
  cursor: pointer;
  position: absolute;
  top: 30px;
  /* left: 50%; */
  transform: translate(-50%, -50%);
}


/* antd time-picker css  */
.time-picker-now-btn {
  color: rgba(164, 22, 20, 1);
}
.time-picker-now a:hover {
  color: rgba(164, 22, 20, 1) !important;
}

.ant-btn-primary {
  background: rgba(164, 22, 20, 1);
}

.time-picker-ok > .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: rgba(164, 22, 20, 1) !important;
}

.ant-picker-dropdown .ant-picker-today-btn,.ant-picker-today-btn:hover,.ant-picker-today-btn:active {
  color: rgba(164, 22, 20, 1)!important;
}
