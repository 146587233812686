.wrapper-class {
  border: 1px solid rgba(255, 255, 255, 0.51);
  border-radius: 6px;
}
.editor-class {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 25px;
  height: 300px;
  color: rgba(255, 255, 255, 0.79);
}
.editor-class::-webkit-scrollbar {
  width: 0px;
}
.editor-class .DraftEditor-root {
  height: calc(100% - 15px);
}
.toolbar-class {
  margin-bottom: 0px;
}
.error_text {
  font-weight: 400;
  font-size: 0.75rem;
  color: #d32f2f;
  padding: 5px 0 0 20px;
}
.rdw-editor-wrapper {
  color: black;
}
